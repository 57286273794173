import { functions } from '@/firebase'
import { httpsCallable } from 'firebase/functions'
import { defineStore } from 'pinia'

import { IProRawProject } from '@/__types__/_pro/raw-project/pro-raw-project'
import { IProRawProjectAccount } from '@/__types__/_pro/raw-project/pro-raw-project-account'
import { ProProject } from '@/globals/javascript/models/ProProject/ProProject'

import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import {
  compileStacks,
  fromStacksToOnlineTypes,
  useStackAmount,
  mergeDuplicates,
} from '@/store/modules/pro-projects/helpers'
import {
  appStore,
  filterStore,
  resourcesStore,
  translationsStore,
} from '@/store'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { combineNotAloneTypes } from '@/store/modules/pro-projects/helpers/combineNotAloneTypes'
import { deriveCleaningTasks } from '@/store/modules/pro-projects/helpers/deriveCleaningTasks'
import { setStackM2 } from '@/store/modules/pro-projects/helpers/setStackM2'
import { captureException } from '@sentry/vue'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import { splitProTypes } from '@/store/modules/pro-projects/helpers/splitProTypes'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { checkForDuplicatedIDs } from '@/store/modules/pro-projects/helpers/checkForDuplicatedIDs'

interface IFirebaseResponseSuccess {
  projects: IProRawProject[]
  account: IProRawProjectAccount
}

interface IFirebaseResponseError {
  code: string
  message: string
  id: string
}

interface IProRawProjectState {
  dataFetched: boolean
  rawProjects: IProRawProject[]
  projects: ProProject[]
  account: IProRawProjectAccount | null
}

export default defineStore('pro-projects', {
  state: () =>
    ({
      dataFetched: false,
      rawProjects: [],
      projects: [],
      account: null,
    } as IProRawProjectState),

  actions: {
    async fetchProjects(
      origin: unknown
    ): Promise<void | IFirebaseResponseError> {
      const callProject = httpsCallable<unknown, IFirebaseResponseSuccess>(
        functions,
        'online_v2_getProjects_gen2'
      )
      const { setBootingStateText } = appStore()

      try {
        setBootingStateText('getting-data')

        const { data } = await callProject(origin)

        setBootingStateText('preparing-project')

        const { projects, account } = data

        this.$patch({
          rawProjects: projects,
          account,
        })

        return
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        captureException(e)

        if (e.details) {
          const { code, message, id } = e.details

          return {
            code,
            message,
            id,
          }
        }

        // Handling JSON encoding error from firebase
        // - Typical culprit is test result data upload
        return {
          code: 'unknown-internal-error',
          message: 'ERROR_OCCURRED',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          id: (origin as any).id,
        }
      }
    },
    instantiateProjects() {
      this.$patch({
        projects: this.rawProjects.map((x) => new ProProject(x)),
      })

      // Set web data
      this.$state.projects.forEach((project) => {
        project.setProjectWebData()
        project.samples.forEach((sample) => sample.setWebData())
        project.types.forEach((pType) => pType.setWebData())
      })

      this.dataFetched = true
    },
  },

  getters: {
    allUnits(state): (Building | Floor | Apartment)[] {
      const units = state.projects.map((project) => project.units).flat(1)

      checkForDuplicatedIDs('Unit', units)

      return units
    },
    allSamples(state) {
      const samples = state.projects.map((project) => project.samples).flat(1)

      checkForDuplicatedIDs('Sample', samples)

      return samples
    },
    allSplitSamples(state) {
      return state.projects.map((project) => project.splitSamples).flat(1)
    },
    allPTypes(state): ProType[] {
      return state.projects.map((project) => project.types).flat(1)
    },
    onlineTypes(state) {
      const { dataFetched: ressourcesFetched } = resourcesStore()
      const { translationsFethched } = translationsStore()
      const {
        values: { projectId },
      } = filterStore()

      const dataNotReady =
        !this.dataFetched ||
        !ressourcesFetched ||
        !translationsFethched ||
        !projectId

      if (dataNotReady) {
        return []
      }

      const allOnlineTypes: OnlineType[] = []

      state.projects.forEach((project) => {
        let onlineTypes: OnlineType[] = []

        const { id, interConnections, samples, splitSamples } = project
        const { interConnectionGroupAmounts } = project.data
        const { types } = project

        // 1. Derive and compile stack informations
        const stacks = compileStacks(interConnections, types)

        // 2. Check if amount is required for each stack
        stacks.forEach(useStackAmount)

        // 3 Set m2 per stack
        setStackM2(stacks, interConnectionGroupAmounts, types)

        // 4. Split coating from surfaces
        stacks.forEach(splitProTypes)

        // 4.1 Combine samples and splitSamples
        const combinedSamples = [...samples, ...splitSamples]

        // 5. Convert stack layers to OnlineType instances
        onlineTypes = fromStacksToOnlineTypes(stacks, id)

        // 6. Set oType data - round 1
        onlineTypes.forEach((oType) => {
          // 6.1 Set WDG ID
          oType.setWDGIDs()

          // 6.2 Set special cases
          oType.setSpecialCases()

          // 6.3 Set WDC ID's
          oType.setOwnWDCIDs(combinedSamples as (ProSample | ProSplitSample)[])

          // 6.4 Pre set status of postponed sample and missing test results
          oType.setMissingTestResultsStatus()
          oType.setHasPostponedSampleStatus()
        })

        // 7. Combine 'never alone' types
        combineNotAloneTypes(
          onlineTypes,
          combinedSamples as (ProSample | ProSplitSample)[]
        )

        // 8. Set oType data - round 2
        onlineTypes.forEach((oType) => {
          // 8.1 Set ID's of pTypes
          oType.typeIDs = oType.types.map((x) => x.id)

          // 8.2 Set dublicate identifier
          oType.dubID = oType.typeIDs.join('+')

          // 8.3 Set custom type state
          oType.setCustomTypeState()

          // 8.4 Set final WDC ID
          oType.setFinalWCDID()

          // 8.5 Set multi type status
          oType.setMultiTypeStatus()

          // 8.6 Set potential contaminated status
          oType.setIsPotentiallyContaminated()
        })

        // 9. Set oType data - round 3
        onlineTypes.forEach((oType) => {
          // 9.1 Derrive cleaning tasks and their area
          deriveCleaningTasks(oType)
        })

        // 10. Merge duplicates
        onlineTypes = mergeDuplicates(onlineTypes)

        // 11. Set oType data - round 4
        const { categoriesAsArray } = resourcesStore()
        categoriesAsArray.forEach((category, catIndex) => {
          const onlineTypesInCategory = onlineTypes.filter(
            (oType) => oType.categoryID === category.id
          )

          onlineTypesInCategory.forEach((oType, subIndex) => {
            // 11.1 Set indexes
            oType.index.primary = catIndex + 1
            oType.index.secondary = subIndex + 1

            // 11.2 Update sample status for merged duplicates
            oType.setMissingTestResultsStatus()
            oType.setHasPostponedSampleStatus()

            // 11.3 Add Fraction and EWC code
            oType.setFractionAndEWCCode()

            // 11.4 Set titles
            oType.setTitles()

            // 11.5 Set amounts
            oType.setAmount()
          })
        })

        // 12. Set oType data - round 5
        onlineTypes.forEach((oType) => {
          // 12.1 Update amounts from cleaning tasks
          oType.setAmountFromCleaningTasks()

          // 12.2 Set disposal cost
          oType.setDisposalCost()

          // 12.3 Set units
          oType.setUnitIDsAndText()

          // 12.4 Set samples
          oType.setSamples()

          // 12.5 Set rooms
          oType.setRooms()

          // 12.6 Set is recycable state
          oType.setRecycableState()

          // 12.7 Check and reset course of action if needed
          oType.setCourseOfAction()

          // 12.8 Set hashID
          oType.setHashID()

          // 12.9 Set status text and class
          oType.setStatus()

          // 12.10 Set CO2
          oType.setCO2()
        })

        allOnlineTypes.push(...onlineTypes)
      })

      // Check for duplicate oType hash ID's
      checkForDuplicatedIDs('OnlineType', allOnlineTypes)
      return allOnlineTypes
    },
  },
})
