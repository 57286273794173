
import FeedbackForm from '@/components/feedback/FeedbackForm.vue'
import FeedbackReceipt from '@/components/feedback/FeedbackReceipt.vue'
import Spinner from '@/components/Spinner.vue'
import storageKeys from '@/globals/javascript/constants/storageKeys'
import { HOST } from '@/globals/javascript/_utils/host'
import { appStore, currentStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeedbackFlow',
  data() {
    return {
      states: {
        success: false,
        error: false,
        showReceipt: false,
        isLoading: false,
      },
      params: {
        rating: 0,
        message: '',
      },
      missingInputs: [] as HTMLInputElement[],
    }
  },
  computed: {
    ...mapState(currentStore, ['projectAddress']),
  },
  watch: {
    params: {
      handler() {
        this.missingInputs.forEach((el) => {
          if (el.value.trim()) {
            el.classList.remove('Error')
          }
        })
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
    onGoBack() {
      this.states.showReceipt = false
      this.states.error = false
    },
    async onSubmit() {
      if (!this.params.message.trim()) {
        this.highlightMissingRequiredData()
        return
      }

      // Init spinner
      this.states.isLoading = true

      // Get correct baseURL
      let baseURL = ''

      if (
        process.env.VUE_APP_EMULATORS === 'all' ||
        process.env.VUE_APP_EMULATORS?.includes('functions')
      ) {
        baseURL = 'http://localhost:5001/milva-pro-dev/europe-west3'
      } else {
        baseURL =
          process.env.NODE_ENV === 'development'
            ? 'https://europe-west3-milva-pro-dev.cloudfunctions.net'
            : 'https://europe-west3-milva-pro-prod.cloudfunctions.net'
      }

      // Execute fetch
      const response = await fetch(
        `${HOST.cloudFunction}/online_shared_feedbackAPI/feedback_gen2`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            origin: 'online',
            url: window.location.href,
            projectID: this.$route.params.projectID,
            projectAddress: this.projectAddress,
            ...this.params,
          }),
        }
      )

      // Prep correct receipt
      const feedbackCreated = response.status === 201

      this.states.success = feedbackCreated
      this.states.error = !feedbackCreated

      // Show receipt
      this.states.showReceipt = true
      this.states.isLoading = false
    },
    getMissingRequiredInputs() {
      const requiredInputs = [
        ...this.$el.querySelectorAll('[required]'),
      ] as HTMLInputElement[]
      return requiredInputs.filter((el) => !el.value.trim())
    },
    highlightMissingRequiredData() {
      this.missingInputs = this.getMissingRequiredInputs()

      this.missingInputs.forEach((el) => el.classList.add('Error'))
    },
  },
  components: { FeedbackForm, Spinner, FeedbackReceipt },
  mounted() {
    localStorage.setItem(storageKeys.LS_FEEDBACK_SEEN, 'true')
  },
})
